import React from 'react'
import loadable from "@loadable/component";

const ArticlePreview = loadable(() => import("../../templates/articlePreview.amp"));

const WhatNext = (props) => {

    return (
        <div className="container container--halves container--lined what-next">
            <div className="popular-posts-top">WHAT TO READ NEXT...</div>
            <div className="container-grid">
                {props.recommendedPosts.map((item) => {
                    return <div className="container-grid__item" key={item.slug}>
                        <ArticlePreview data={item} noBody={true} noAuthor={true}/>
                    </div>
                })}
            </div>
        </div>
    )
}

export default WhatNext
